<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
      <div class="row">
        <div class="col-md-4">
          <h4>super admin</h4>
        </div>
            <div class="col-md-4 mb-3 mx-auto me-0">
            <div class="d-flex align-items-center gap-2">
                <div class="icon-wrapper mt-3 pt-1">
                    <img src="@/assets/images/profile-manager/icon-11.png" width="21" alt="" />
                </div>
                <div class="form-field__wrapper w-100">
                    
                    <label>Select Location</label>
                    
                    <select v-model="selectedData"  id="emp_job_type" class="form-select" 
                    >
                        <option :value="dashboard" v-for="(dashboard,index) in dashBoard_datas" :key="index" >{{dashboard.location.name}}</option>
                    </select>
            
                </div>
            </div>
        </div>
    </div>
    <Stat :analytics="selectedData" />

    <div class="row">
        <div class="col-md-12" >
          <div class="dashboard__wrapper card">
            <div class="card-body">
            <h2 class="f-20 poppins">Shifts in Progress</h2>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="">
                  <tr>
                    <!-- <th class="f-14 roboto">Sr.#</th> -->
                   <th class="f-14 roboto">Shift Name</th>
                      <th class="f-14 roboto">Days</th>
                   <th class="f-14 roboto">Shift Start Time</th>
                   <th class="f-14 roboto">Shift End Time</th>
                    <th class="f-14 roboto">Lock Day</th>
                       <th class="f-14 roboto">Area Role</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(shift , index ) in shiftInProgress" :key="index">
                    <!-- <td class="f-14 roboto"> {{index+1}}</td> -->
                    <td class="f-14 roboto"> {{shift.name}}</td>
                     <td class="f-14 roboto"> {{shift.interval}}</td>
                    <td class="f-14 roboto">{{shift.from}}</td>
                    <td class="f-14 roboto">{{shift.to}}</td>
                    <td class="f-14 roboto"> {{selectedData.location.lock_date}}</td>
                  
                   <td class="f-14 roboto">{{rolesToString(shift.area_roles)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
         <div class="col-md-12" >
          <div class="dashboard__wrapper card">
            <div class="card-body">
            <h2 class="f-20 poppins">New Shifts</h2>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="">
                  <tr>
                    <!-- <th class="f-14 roboto">Sr.#</th> -->
                   <th class="f-14 roboto">Shift Name</th>
                      <th class="f-14 roboto">Days</th>
                   <th class="f-14 roboto">Shift Start Time</th>
                   <th class="f-14 roboto">Shift End Time</th>
                    <th class="f-14 roboto">Lock Day</th>
                       <th class="f-14 roboto">Area Role</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(shift , index ) in newShifts" :key="index">
                    <!-- <td class="f-14 roboto"> {{index+1}}</td> -->
                    <td class="f-14 roboto"> {{shift.name}}</td>
                     <td class="f-14 roboto"> {{shift.interval}}</td>
                    <td class="f-14 roboto">{{shift.from}}</td>
                    <td class="f-14 roboto">{{shift.to}}</td>
                    <td class="f-14 roboto">{{selectedData.location.lock_date}}</td>
                  
                   <td class="f-14 roboto">{{rolesToString(shift.area_roles)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
         <div class="col-md-12" >
          <div class="dashboard__wrapper card">
            <div class="card-body">
            <h2 class="f-20 poppins">Bonus Shifts</h2>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="">
                  <tr>
                      <!-- <th class="f-14 roboto">Sr.#</th> -->
                   <th class="f-14 roboto">Shift Name</th>
                      <th class="f-14 roboto">Day</th>
                   <th class="f-14 roboto">Shift Start Time</th>
                   <th class="f-14 roboto">Shift End Time</th>
                   <th class="f-14 roboto">Bonus Amount</th>
                    <th class="f-14 roboto">Lock Day</th>
                       <th class="f-14 roboto">Area Role</th>
                 
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(shift , index ) in bonusShift" :key="index">
                   <!-- <td class="f-14 roboto"> {{index+1}}</td> -->
                    <td class="f-14 roboto"> {{shift.name}}</td>
                     <td class="f-14 roboto"> {{shift.interval}}</td>
                    <td class="f-14 roboto">{{shift.from}}</td>
                    <td class="f-14 roboto">{{shift.to}}</td>
                    <td class="f-14 roboto">{{shift.bonus}}$</td>
                    <td class="f-14 roboto">{{selectedData.location.lock_date}}</td>
                  
                   <td class="f-14 roboto">{{rolesToString(shift.area_roles)}}</td>
              
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" >
          <div class="dashboard__wrapper card">
            <div class="card-body">
            <h2 class="f-20 poppins">Managers</h2>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="">
                  <tr>
                    <!-- <th class="f-14 roboto">Sr.#</th> -->
                   <th class="f-14 roboto">Manager Name</th>
                    <th class="f-14 roboto">User Email</th>
                   <th class="f-14 roboto">Contact</th>
                      <th class="f-14 roboto">Created At</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(manager , index ) in managerLists" :key="index">
                    <!-- <td class="f-14 roboto">{{index+1}}</td> -->
                    <td class="f-14 roboto">
                       <div class="d-flex align-items-center">
                       <div class="user-imgs">
                       <img :src="manager.user.avatar_path" width="30" height="30" class="rounded-circle" alt="">
                     
                       </div>
                      <div class="user-count ms-3">
                       <span class="fw-bold">{{manager.user.name}}</span>
                      </div>

                     </div>
                    </td>
                    <td class="f-14 roboto">{{manager.user.email}}</td>
                    <td class="f-14 roboto w-25">{{manager.user.phone}}</td>
                     <td class="f-14 roboto">{{manager.user.created_at_formatted}}</td>
             
              
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/views/pages/super_admin/layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";

import Stat from "@/views/pages/super_admin/dashboard/widgets/stat";




export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat,
 
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Shift Fish",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      dashBoard_datas:[],
      selectedData:{},

// Shift In Progress//
      shiftInProgress: [],
       newShifts: [],
        bonusShift: [],
        managerLists: [],
    };
  },
         watch: {
        selectedData: {
            handler: function (new_data, old) {
                if (new_data) {
                   this.shiftInProgress=new_data.progress_shifts;
                   this.bonusShift=this.shiftInProgress.filter((shift)=>(shift.shift_type=='Premium'))
                   this.shiftInProgress=this.shiftInProgress.filter((shift)=>(shift.shift_type=='Non_Premium'))
                   this.newShifts=new_data.new_shifts;
                   this.managerLists=new_data.managers;
                }
            },
            // immediate: true,
            deep:true
        },
         },

   mounted() {
    this.fetchDashboardStatics();
  },

  methods: {
    fetchDashboardStatics() {
      this.$axios.get("/dashboard/index").then((response) => {
        let data = response.data.payload;
        this.dashBoard_datas = data;
        this.selectedData = data[0];
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      }).finally(function () {
      });
    },
     rolesToString(areaRoles) {
      let name_array = areaRoles.map((role) => {
        return role.name;
      });
      let string = name_array.toString();

      return string;
    }, 
  }
};
</script>